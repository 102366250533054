<template>
	<div>
		<div class="screen-title">NFT 登録履歴</div>
		<b-container fluid>
			<card class="w-100 my-0 mb-4">
				<b-row class="mx-4 d-flex align-items-center col-gap-20">
					<div class="elm-title">表示件数</div>
					<el-select
						class="w-147px"
						v-model="pagination.perPage"
						@change="onChangePagitionPerPage"
					>
						<el-option
							v-for="item in pagination.perPageOptions"
							:key="item"
							:label="item"
							:value="item"
						>
						</el-option>
					</el-select>
				</b-row>
				<b-row class="mx-0 px-4 my-3">
					<el-table
						:data="queriedData"
						row-key="id"
						@sort-change="sortChange"
						v-touch:swipe="ScrollTable"
					>
						<el-table-column
							width="180"
							v-for="col in projectNameCol"
							:key="col.label"
							v-bind="col"
						>
							<template v-slot:default="table">
								<span>{{ table.row.projectName }}</span>
							</template>
						</el-table-column>
						<el-table-column
							v-for="column in tableColumns"
							:key="column.label"
							v-bind="column"
						>
						</el-table-column>
						<el-table-column
							width="150"
							v-for="col in nftUrlCol"
							:key="col.label"
							v-bind="col"
						>
							<template v-slot:default="table">
								<div class="nftRow">
									<span>
										<img
											class="logo__openSea"
											src="../../assets/Logo/OpenSea.svg"
											alt=""
										/>
									</span>
									<span>
										<a target="_blank" :href="table.row.url">NFT詳細</a>
									</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<img
						src="@/assets/Icon/ScrollHintldpi.svg"
						class="logo__scrollHint"
						v-show="!isScrolling"
						:class="{ not_display: !isMobile }"
					/>
				</b-row>
				<div class="w-100 d-flex justify-content-end justify-content-sm-between">
					<base-pagination
						class="w-100 d-flex justify-content-end"
						:current="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total"
						@change="paginationChanged($event)"
					>
					</base-pagination>
				</div>
			</card>
		</b-container>
	</div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from '@/components';
import clientPaginationMixin from '../../mixin/clientPaginationMixin';
import socketClient from '../../services/socket/SOCKET';

export default {
	mixins: [clientPaginationMixin],
	components: {
		BasePagination,
		[Select.name]: Select,
		[Option.name]: Option,
		[Table.name]: Table,
		[TableColumn.name]: TableColumn,
	},
	data() {
		return {
			isMobile: false,
			isScrolling: false,
			tableColumns: [
				{
					prop: 'fileName',
					label: '登録ファイル名',
					minWidth: 180,
					sortable: true,
				},
				{
					prop: 'registerDate',
					label: '登録日',
					minWidth: 150,
					sortable: true,
				},
			],
			projectNameCol: [
				{
					prop: 'projectName',
					label: '案件名',
					minWidth: 180,
					sortable: true,
				},
			],
			nftUrlCol: [
				{
					prop: 'url',
					label: '',
					minWidth: 120,
					sortable: false,
				},
			],
			tableData: [],
			tableName: 'nft-table',
			loginUserId: '',
		};
	},
	methods: {
		onChangePagitionPerPage() {
			this.pagination.currentPage = 1;
		},
		checkMobile() {
			let check = false;
			(function (a) {
				if (
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
						a
					) ||
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
						a.substr(0, 4)
					)
				)
					check = true;
			})(navigator.userAgent || navigator.vendor || window.opera);
			console.log(check);
			return check;
		},
		ScrollTable() {
			this.isScrolling = true;
		},
		paginationChanged(page) {
			this.pagination.currentPage = page;
		},
	},
	mounted() {
		this.isMobile = this.checkMobile();
		socketClient.listen('new_data_transfer', this.dataTransferListener);
	},
	beforeDestroy() {
		socketClient.removeListener('new_data_transfer', this.dataTransferListener);
	},
};
</script>
<style lang="scss" scoped>
.logo {
	&__scrollHint {
		width: 30%;
		height: 30%;
		left: 35%;
		top: 30%;
		margin: 0 auto;
		position: absolute;
	}
	&__openSea {
		width: 20px;
		height: 20px;
	}
}

.nftRow {
	display: flex;
	flex-direction: row;
	column-gap: 7px;
	& a {
		color: #666666;
		text-decoration: underline;
	}
}

.not_display {
	display: none !important;
}
</style>
